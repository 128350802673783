.glass {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  min-width: 300px;
  padding: 5px;
}

.painel {
  padding: 5px;

  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;

  text-align: center;
  z-index: 0;
}

.row {
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left {
  justify-items: left;
  text-align: left;
  align-items: left;
}

.Relogio{
  left: 20%;
  bottom: 0%;
  width: 300px !important;
  position: fixed;
  transform: translate(-50%, -50%);
}