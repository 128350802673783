.backImg {
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0; 

    left: 0;
    top: 0;

    background-image: url("../planoDeFundo/img(3).jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
}

.nav-Painel {
    padding: 5px;

    position: fixed;
    left: 10px;
    top: 10px;

    text-align: center;
    z-index: 1;
}

.nav-a:link,
.nav-a:visited {
    background-color: #ffffff00;
    font-size: 16px;
    color: #333333;
    font-weight: 700;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 12px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 16px;
}

.nav-a:hover,
.nav-a:active {
    background-color: #ffffff65;
}